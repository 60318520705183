import React from "react";
export const FaqData = {
    OverPack: {
        className: 'home-page-wrapper content13-wrapper ktuaj5xcu68-editor_css',
        playScale: 0.3,
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [
            {
                name: 'image',
                children: 'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
                className: 'title-image',
            },
            {
                name: 'title',
                children: (<span><p>FAQ</p></span>),
                className: 'title-h1',
            },
        ],
    },
};
