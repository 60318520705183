import React from 'react';
import {
    ABOUT_LINK,
    AUDIT_LINK,
    BUY_LINK, GITHUB_LINK, GOVERNANCE_LINK,
    INFO_ADDRESS_LINK,
    METRICS_LINK,
    RAE_DISCORD_LINK,
    RAE_MEDIUM_LINK,
    RAE_TWITTER_LINK, REWARDS_LINK,
    ROKFIN_FAQ_LINK,
    ROKFIN_LINK,
    ROKFIN_PATENTS_LINK,
    WHITEPAPER_LINK
} from "../util/Constants";
import moment from "moment";

export const AppFooterData = {
    wrapper: { className: 'home-page-wrapper footer1-wrapper' },
    OverPack: { className: 'footer1', playScale: 0.2 },
    block: {
        className: 'home-page',
        gutter: 0,
        children: [
            {
                name: 'block0',
                xs: 24,
                md: 9,
                className: 'block',
                title: {
                    className: '',
                    children: (<span><p><img src='https://d22z6rj5fefjac.cloudfront.net/assets/Logos/Rae_Logo_White.png' width="28px" alt='' style={{marginRight: 10, marginBottom: 5}} /> RAE Token</p></span>),
                },
                childWrapper: {
                    className: 'slogan',
                    children: [
                        {
                            name: 'content0',
                            children: (<span><p>Compensation Protocol for Creators</p></span>),
                        },
                        {
                            name: 'content1',
                            children: (<span><p>{INFO_ADDRESS_LINK}</p></span>),
                        },
                    ],
                },
            },
            {
                name: 'block1',
                xs: 24,
                md: 5,
                className: 'block',
                title: {
                    children: (<span><p>RAE</p></span>),
                },
                childWrapper: {
                    children: [
                        {
                            name: 'link0',
                            href: ABOUT_LINK,
                            rel: "noopener noreferrer",
                            target: "_blank",
                            children: (<span><p>About</p></span>),
                        },
                        {
                            name: 'link1',
                            href: GOVERNANCE_LINK,
                            rel: "noopener noreferrer",
                            target: "_blank",
                            children: (<span><p>Governance</p></span>),
                        },
                        {
                            name: 'link2',
                            href: BUY_LINK,
                            rel: "noopener noreferrer",
                            target: "_blank",
                            children: (<span><p>Buy Tokens</p></span>),
                        },
                        {
                            name: 'link2',
                            href: METRICS_LINK,
                            rel: "noopener noreferrer",
                            target: "_blank",
                            children: (<span><p>Metrics</p></span>),
                        },
                        {
                            name: 'link3',
                            href: WHITEPAPER_LINK,
                            rel: "noopener noreferrer",
                            target: "_blank",
                            children: (<span><p>White paper</p></span>),
                        },
                        {
                            name: 'link4',
                            href: AUDIT_LINK,
                            rel: "noopener noreferrer",
                            target: "_blank",
                            children: (<span><p>Audit</p></span>),
                        },
                    ],
                },
            },
            {
                name: 'block2',
                xs: 24,
                md: 5,
                className: 'block',
                title: {
                    children: (<span><p>Rokfin</p></span>),
                },
                childWrapper: {
                    children: [
                        {
                            name: 'link0',
                            href: `${ROKFIN_LINK}/about`,
                            rel: "noopener noreferrer",
                            target: "_blank",
                            children: (<span><p>About</p></span>),
                        },
                        {
                            name: 'link1',
                            href: ROKFIN_FAQ_LINK,
                            rel: "noopener noreferrer",
                            target: "_blank",
                            children: (<span><p>FAQ</p></span>),
                        },
                        {
                            name: 'link2',
                            href: ROKFIN_PATENTS_LINK,
                            rel: "noopener noreferrer",
                            target: "_blank",
                            children: (<span><p>Patents</p></span>),
                        },
                    ],
                },
            },
            {
                name: 'block3',
                xs: 24,
                md: 5,
                className: 'block',
                title: {
                    children: (<span><p>Follow</p></span>),
                },
                childWrapper: {
                    children: [
                        {
                            name: 'link0',
                            href: RAE_TWITTER_LINK,
                            rel: "noopener noreferrer",
                            target: "_blank",
                            children: (<span><p>Twitter</p></span>),
                        },
                        {
                            name: 'link1',
                            href: RAE_DISCORD_LINK,
                            rel: "noopener noreferrer",
                            target: "_blank",
                            children: (<span><p>Discord</p></span>),
                        },
                        {
                            name: 'link2',
                            href: RAE_MEDIUM_LINK,
                            rel: "noopener noreferrer",
                            target: "_blank",
                            children: (<span><p>Medium</p></span>),
                        },
                        {
                            name: 'link3',
                            href: GITHUB_LINK,
                            rel: "noopener noreferrer",
                            target: "_blank",
                            children: (<span><p>GitHub</p></span>),
                        },
                    ],
                },
            },
        ],
    },
    copyrightWrapper: { className: 'copyright-wrapper' },
    copyrightPage: { className: 'home-page' },
    copyright: {
        className: 'copyright',
        children: (<span>©{moment().year()} by Rokfin. All Rights Reserved.</span>),
    },
};
