/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react'
import Banner from './Banner'
import RaeMetrics from './RaeMetrics'
import Point from './Point'
import { BannerData } from '../../data/BannerData'
import { RaeMetricsData } from '../../data/RaeMetricsData'
import AboutRae from './AboutRae'
import { AboutRaeData } from '../../data/AboutRaeData'
import RaeUsage from './RaeUsage'
import { RaeUsageData } from '../../data/RaeUsageData'
import RokfinUseCase from './RokfinUseCase'
import { RokfinUseCaseData } from '../../data/RokfinUseCaseData'
import TokenDetails from './TokenDetails'
import { TokenDetailsData } from '../../data/TokenDetailsData'
import CreatorsDilemma from './CreatorsDilemma'
import { CreatorsDilemmaData } from '../../data/CreatorsDilemmaData'
import RaeDistribution from './RaeDistribution'
import { RaeDistributionData } from '../../data/RaeDistributionData'
import { FaqData } from '../../data/FaqData'
import Faq from './Faq'

export default class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: true,
    }
  }

  render() {
    const children = [
      <Banner
        id="Banner"
        key="Banner"
        dataSource={BannerData}
        isMobile={this.props.isMobile}
      />,
      <RaeMetrics
        id="RaeMetrics"
        key="RaeMetrics"
        dataSource={RaeMetricsData}
        isMobile={this.props.isMobile}
      />,
      <AboutRae
        id="AboutRae"
        key="AboutRae"
        dataSource={AboutRaeData}
        isMobile={this.props.isMobile}
      />,
      <RaeUsage
        id="RaeUsage"
        key="RaeUsage"
        dataSource={RaeUsageData}
        isMobile={this.props.isMobile}
      />,
      <RokfinUseCase
        id="RokfinUseCase"
        key="RokfinUseCase"
        dataSource={RokfinUseCaseData}
        isMobile={this.props.isMobile}
      />,
      <TokenDetails
        id="TokenDetails"
        key="TokenDetails"
        dataSource={TokenDetailsData}
        isMobile={this.props.isMobile}
      />,
      <CreatorsDilemma
        id="CreatorsDilemma"
        key="CreatorsDilemma"
        dataSource={CreatorsDilemmaData}
        isMobile={this.props.isMobile}
      />,
      <RaeDistribution
        id="RaeDistribution"
        key="RaeDistribution"
        dataSource={RaeDistributionData}
        isMobile={this.props.isMobile}
      />,
      <Faq
        id="Faq"
        key="Faq"
        dataSource={FaqData}
        isMobile={this.props.isMobile}
      />,
      <Point
        key="list"
        data={[
          'Banner',
          'RaeMetrics',
          'AboutRae',
          'RaeUsage',
          'RokfinUseCase',
          'TokenDetails',
          'CreatorsDilemma',
          'RaeDistribution',
          'Faq',
        ]}
        position="point-left"
      />,
    ]
    return (
      <div
        className="templates-wrapper"
        style={{ overflow: 'hidden' }}
        ref={(d) => {
          this.dom = d
        }}
      >
        {this.state.show && children}
      </div>
    )
  }
}
