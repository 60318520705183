import React from "react";
export const RaeUsageData = {
    OverPack: {
        className: 'home-page-wrapper content13-wrapper ktu9y37kq4-editor_css',
        playScale: 0.3,
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [
            {
                name: 'image',
                children: 'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
                className: 'title-image',
            },
            {
                name: 'title',
                children: (<span><p>RAE is For Creators, Platforms, Customers and Fans</p></span>),
                className: 'title-h1',
            },
            {
                name: 'content',
                children: (
                    <span>
                        <p>
                            RAE is for creators that want to earn the full value they bring to a network,
                            which depends on their contributions' timing. The RAE compensation protocol
                            allows creators to get their full value from networks because it inseparately
                            links creator payments to a predetermined minting schedule of RAE Token distributions.
                            RAE is for platforms that desire to distribute content from
                            creators hooked into the RAE protocol. RAE is for customers, fans, and
                            other crypto enthusiasts because they are helping to support a 10x more
                            effective marketplace network.
                        </p>
                    </span>
                ),
                className: 'title-content ktu9yvgsape-editor_css',
            },
        ],
    },
};
