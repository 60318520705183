import {BUY_LINK, RAE_DISCORD_LINK} from "../util/Constants";

export const BannerData = {
    wrapper: { className: 'banner2' },
    BannerAnim: {
        children: [
            {
                name: 'elem0',
                BannerElement: { className: 'banner-user-elem' },
                page: { className: 'home-page banner2-page' },
                textWrapper: { className: 'banner2-text-wrapper' },
                bg: { className: 'bg bg0' },
                title: {
                    className: 'banner2-title',
                    children: 'Compensation Protocol for Creators'
                },
                subtitle: {
                    className: 'banner2-subtitle',
                    children: 'Flipping the “creator economy” on its head',
                },
                content: {
                    className: 'banner2-content',
                    children: 'The RAE token aligns the self-interest of each creator and the digital platform with the performance of the network',
                },
                button: {
                    className: 'banner2-button',
                    children: 'Buy RAE Tokens',
                    href: BUY_LINK,
                    rel: "noopener noreferrer",
                    target: "_blank"
                },
                button1: {
                    className: 'banner2-button1',
                    children: 'Join our Discord',
                    href: RAE_DISCORD_LINK,
                    rel: "noopener noreferrer",
                    target: "_blank"
                },
            },
        ],
    },
};
