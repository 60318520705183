import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import ValueGapImage from '../../assets/value_gap.png';

function CreatorsDilemma(props) {
    const { ...tagProps } = props;
    const { dataSource, isMobile } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    const animType = {
        queue: isMobile ? 'bottom' : 'right',
        one: isMobile
            ? {
                scaleY: '+=0.3',
                opacity: 0,
                type: 'from',
                ease: 'easeOutQuad',
            }
            : {
                x: '-=30',
                opacity: 0,
                type: 'from',
                ease: 'easeOutQuad',
            },
    };
    return (
        <div {...tagProps} {...dataSource.wrapper}>
            <OverPack {...dataSource.OverPack} component={Row}>
                <TweenOne
                    key="img"
                    animation={animType.one}
                    resetStyle
                    {...dataSource.imgWrapper}
                    component={Col}
                    componentProps={{
                        md: dataSource.imgWrapper.md,
                        xs: dataSource.imgWrapper.xs,
                    }}
                >
          <span {...dataSource.img}>
            <img src={ValueGapImage} width="100%" alt="img" />
          </span>
                </TweenOne>
                <QueueAnim
                    key="text"
                    type={animType.queue}
                    leaveReverse
                    ease={['easeOutQuad', 'easeInQuad']}
                    {...dataSource.textWrapper}
                    component={Col}
                    componentProps={{
                        md: dataSource.textWrapper.md,
                        xs: dataSource.textWrapper.xs,
                    }}
                >
                    <h2 key="h1" {...dataSource.title}>
                        {dataSource.title.children}
                    </h2>
                    <div key="p" {...dataSource.content}>
                        {dataSource.content.children}
                    </div>
                </QueueAnim>
            </OverPack>
        </div>
    );
}

export default CreatorsDilemma;
