import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from '../../util/Helpers';

class RokfinUseCase extends React.PureComponent {
    render() {
        const { ...props } = this.props;
        const { dataSource } = props;
        delete props.dataSource;
        delete props.isMobile;
        return (
            <div style={{
                background: 'rgba(250,250,250,1)'
            }}>
                <OverPack {...props} {...dataSource.OverPack}>
                    <QueueAnim
                        type="bottom"
                        leaveReverse
                        key="page"
                        delay={[0, 100]}
                        {...dataSource.titleWrapper}
                    >
                        {dataSource.titleWrapper.children.map(getChildrenToRender)}
                    </QueueAnim>
                </OverPack>
            </div>
        );
    }
}

export default RokfinUseCase;
