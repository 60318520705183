const request = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    });


    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response =>
            response.json().then(json => {
                if(!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        )
};

export function getAllChannels() {
    return request({
        url: "https://prod-api-v2.production.rokfin.com/api/v2/public/channels/all",
        method: 'GET',
    });
}

export function getRaePriceFromUniswapV3() {
    return request({
        url: "https://payment-api.production.rokfin.com/v1/donate/public/rae-price",
        method: 'GET',
    });
}

export function getCirculatingSupply() {
    return request({
        url: "https://prod-api-v2.production.rokfin.com/api/v2/public/growth/supply",
        method: 'GET',
    });
}
