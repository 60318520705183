import React from 'react';
export const RaeMetricsData = {
    wrapper: { className: 'home-page-wrapper feature6-wrapper' },
    OverPack: { className: 'home-page feature6', playScale: 0.3 },
    Carousel: {
        className: 'feature6-content',
        dots: false,
        wrapper: { className: 'feature6-content-wrapper' },
        titleWrapper: {
            className: 'feature6-title-wrapper',
            barWrapper: {
                className: 'feature6-title-bar-wrapper',
                children: { className: 'feature6-title-bar' },
            },
            title: { className: 'feature6-title' },
        },
        children: [
            {
                title: {
                    className: 'feature6-title-text',
                    children: (<span><p>RAE is Live</p></span>),
                },
                className: 'feature6-item',
                name: 'block0',
                children: [
                    {
                        md: 8,
                        xs: 24,
                        className: 'feature6-number-wrapper',
                        name: 'child0',
                        number: {
                            className: 'feature6-number',
                            unit: { className: 'feature6-unit', children: '$' },
                            toText: true,
                            children: 'price',
                        },
                        children: {
                            className: 'feature6-text',
                            children: (<span><p>RAE Price</p></span>),
                        },
                    },
                    {
                        md: 8,
                        xs: 24,
                        className: 'feature6-number-wrapper',
                        name: 'child1',
                        number: {
                            className: 'feature6-number',
                            unit: { className: 'feature6-unit', children: '$' },
                            toText: true,
                            children: 'netted',
                        },
                        children: {
                            className: 'feature6-text',
                            children: (<span><p>Value Creators Netted</p></span>),
                        },
                    },
                    {
                        md: 8,
                        xs: 24,
                        className: 'feature6-number-wrapper',
                        name: 'child2',
                        number: {
                            className: 'feature6-number',
                            unit: null,
                            toText: true,
                            children: 'circulation',
                        },
                        children: {
                            className: 'feature6-text',
                            children: (<span><p>Total Circulating Supply</p></span>),
                        },
                    },
                ],
            },
        ],
    },
};
