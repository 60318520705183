import {
    ABOUT_LINK,
    BUY_LINK,
    GOVERNANCE_LINK,
    METRICS_LINK,
    REWARDS_LINK,
    WHITEPAPER_LINK
} from "../util/Constants";

export const AppHeaderData = {
    isScrollLink: true,
    wrapper: { className: 'header2 home-page-wrapper' },
    page: { className: 'home-page ktbs4xzzmk8-editor_css' },
    logo: {
        className: 'header2-logo',
        children: 'https://d22z6rj5fefjac.cloudfront.net/assets/Logos/Rae_Logo_White.png',
    },
    LinkMenu: {
        className: 'header2-menu',
        children: [
            {
                name: 'ABOUT',
                href: ABOUT_LINK,
                rel: "noopener noreferrer",
                target: "_blank",
                children: 'ABOUT',
                className: 'menu-item',
            },
            {
                name: 'GOVERNANCE',
                href: GOVERNANCE_LINK,
                rel: "noopener noreferrer",
                target: "_blank",
                children: 'GOVERNANCE',
                className: 'menu-item',
            },
            {
                name: 'BUY',
                href: BUY_LINK,
                rel: "noopener noreferrer",
                target: "_blank",
                children: 'BUY',
                className: 'menu-item',
            },
            {
                name: 'METRICS',
                href: METRICS_LINK,
                rel: "noopener noreferrer",
                target: "_blank",
                children: 'METRICS',
                className: 'menu-item',
            },
            {
                name: 'WHITE PAPER',
                href: WHITEPAPER_LINK,
                rel: "noopener noreferrer",
                target: "_blank",
                children: 'WHITE PAPER',
                className: 'menu-item',
            },
        ],
    },
    mobileMenu: { className: 'header2-mobile-menu' },
};
