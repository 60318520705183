import React from "react";
import CreatorDistribution from '../assets/creator_distribution.png';
import PlatformDistribution from '../assets/platform_distribution.png';
import IcoDistribution from '../assets/ico_distribution.png';
import FounderDistribution from '../assets/founder_distribution.png';

export const RaeDistributionData = {
    wrapper: { className: 'home-page-wrapper content0-wrapper' },
    page: { className: 'home-page content0' },
    OverPack: { playScale: 0.3, className: '' },
    titleWrapper: {
        className: 'title-wrapper',
        children: [
            {
                name: 'title',
                children: (<span><p>Token Distribution</p></span>),
            },
        ],
    },
    childWrapper: {
        className: 'content0-block-wrapper',
        children: [
            {
                name: 'block0',
                className: 'content0-block',
                md: 6,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children: (<img src={CreatorDistribution} alt='' />),
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children: (<span><p>Creators</p></span>),
                        },
                    ],
                },
            },
            {
                name: 'block1',
                className: 'content0-block',
                md: 6,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children: (<img src={PlatformDistribution} alt='' />),
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children: (<span><p>Platforms</p></span>),
                        },
                    ],
                },
            },
            {
                name: 'block2',
                className: 'content0-block',
                md: 6,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children: (<img src={FounderDistribution} alt='' />),
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children: (<span><p>Founder Rewards</p></span>),
                        },
                    ],
                },
            },
            {
                name: 'block3',
                className: 'content0-block',
                md: 6,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children: (<img src={IcoDistribution} alt='' />),
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children: (<span><p>Pre-Sale/ICO</p></span>),
                        },
                    ],
                },
            },
        ],
    },
};
