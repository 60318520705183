import React from 'react';

export const RokfinUseCaseData = {
    OverPack: {
        className: 'home-page-wrapper content13-wrapper ktu9z7sxhkt-editor_css',
        playScale: 0.3,
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [
            {
                name: 'image',
                children:
                    'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
                className: 'title-image',
            },
            {
                name: 'title',
                children: (<span><p>The Rokfin Use Case</p></span>),
                className: 'title-h1',
            },
            {
                name: 'content',
                children: (
                    <span>
                        <p>
                            On YouTube and other platforms, executives and their product manager cronies
                            frequently alter their economic models and publishing rules to appease the
                            demands of advertisers. Rokfin, on the other hand, is the first platform to use the
                            RAE compensation protocol to benefit creators. Creators on Rokfin are rewarded using
                            an open and transparent, proprietary methodology that compensates for their pro-rata
                            share of the network surplus and ongoing royalties for the lifetime value of their subscribers.
                        </p>
                    </span>
                ),
                className: 'title-content',
            },
        ],
    },
};
