import React, {useEffect, useState} from 'react';
import {getCirculatingSupply} from "../../util/API";

function CirculatingSupply(props) {
    const [circulatingSupply, setCirculatingSupply] = useState(0);

    useEffect( () => {

        async function fetchData() {
            let data = await getCirculatingSupply();

            setCirculatingSupply(Math.round(data.total_circulating_supply));
        }

        fetchData();
    }, []);


    return <b>{circulatingSupply.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</b>;
}

export default CirculatingSupply;
