export const BUY_LINK = 'https://raetoken.medium.com/buy-and-hold-rae-tokens-like-a-pro-faa75b5ab7bd';
export const LIQUIDITY_LINK = 'https://raetoken.medium.com/how-to-add-liquidity-on-the-rae-network-f0c196b82861';
export const METRICS_LINK = 'https://rokfin.com/rae/dashboard';
export const RAE_UNISWAP_LINK = 'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xe5a3229ccb22b6484594973a03a3851dcd948756&chain=mainnet';
export const ROKFIN_LINK = 'https://rokfin.com';
export const REWARDS_LINK = 'https://rokfin.com/stake';
export const WHITEPAPER_LINK = "https://github.com/raetoken/whitepaper/blob/main/whitepaper.pdf";
export const GOVERNANCE_LINK = "https://governance.raetoken.org/";
export const AUDIT_LINK = "https://github.com/solidified-platform/audits/blob/master/Audit%20Report%20-%20%20RAE%20token%20%5B28.03.2019%5D.pdf";
export const ROKFIN_FAQ_LINK = 'https://rokfin.medium.com/rokfin-user-faq-9d8d11f1bbdc';
export const ROKFIN_PATENTS_LINK = 'https://rokfin.com/ip/patents';
export const RAE_TWITTER_LINK = 'https://twitter.com/RAEToken';
export const RAE_DISCORD_LINK = 'https://discord.com/invite/c98TbsEk9r';
export const RAE_MEDIUM_LINK = 'https://raetoken.medium.com/';
export const GITHUB_LINK = 'https://github.com/raetoken';
export const INFO_ADDRESS_LINK = 'info@raetoken.org';
export const ABOUT_LINK = 'https://intro.raetoken.org/';
