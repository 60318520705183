import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import {getCirculatingSupply, getRaePriceFromUniswapV3} from "../../util/API";

TweenOne.plugins.push(Children);

class RaeMetrics extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            price: 0,
            netted: 0,
            circulation: 0
        }
    }

    async componentDidMount() {

        const creatorTakeRae = 300000 * 0.72;

        let price = await getRaePriceFromUniswapV3();
        let data = await getCirculatingSupply();

        this.setState({
            price: price,
            netted: Math.max(0, creatorTakeRae * price) * 12,
            circulation: Math.round(data.total_circulating_supply)
        })
    }


    getChildrenToRender = (dataSource) => {
    const { Carousel } = dataSource;
    const { titleWrapper, children: childWrapper, wrapper } = Carousel;

    const { barWrapper, title: titleChild, ...titleWrapperProps} = titleWrapper;


    const childrenToRender = childWrapper.map((item, ii) => {
      const { title, children, ...itemProps } = item;


      const childrenItem = children.map(($item, i) => {
        const { number, children: child, ...childProps } = $item;
        const { unit, toText, ...numberProps } = number;
        return (
          <Col {...childProps} key={i.toString()}>
              {unit && <span {...unit}>{unit.children}</span>}
            <TweenOne
              {...numberProps}
              animation={{
                Children: {
                  value: parseFloat(this.state[number.children]),
                  floatLength: parseFloat(this.state[number.children]) - Math.floor(parseFloat(this.state[number.children])) > 0 ? 2 : 0,
                  formatMoney: true,
                },
                duration: 1000,
                delay: 300,
                ease: 'easeInOutCirc',
              }}
              component="span"
            >
              0
            </TweenOne>
            <div {...child}>
                {child.children}
            </div>
          </Col>
        );
      });


      return (
        <div key={ii.toString()}>
          <QueueAnim type="bottom" component={Row} {...itemProps}>
            {childrenItem}
          </QueueAnim>
        </div>
      );
    });

    return (
      <QueueAnim
        key="queue"
        leaveReverse
        type="bottom"
        delay={[0, 100]}
        {...wrapper}
      >
        <div {...titleWrapperProps} key="title">
            <div className={childWrapper[0].title.className}>
                {childWrapper[0].title.children}
            </div>
        </div>
        {childrenToRender}
      </QueueAnim>
    );
  };

  render() {
    const { dataSource, isMobile, ...props } = this.props;
    return (
      <div {...props} {...dataSource.wrapper}>
        <div>
          <OverPack {...dataSource.OverPack}>
            {this.getChildrenToRender(dataSource)}
          </OverPack>
        </div>
      </div>
    );
  }
}
export default RaeMetrics;
