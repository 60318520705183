import {useEffect, useState} from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import AppHeader from './components/appHeader/AppHeader';
import AppFooter from "./components/appFooter/AppFooter";
import Home from "./pages/home/index";
import {AppHeaderData} from "./data/AppHeaderData";
import {AppFooterData} from "./data/AppFooterData";
import './App.less';

function App() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        enquireScreen((b) => {
            setIsMobile(!!b);
        });
    }, [])

    return (
        <Router>
            <AppHeader dataSource={AppHeaderData} isMobile={isMobile} />
            <Route exact path="/" component={Home} />
            <AppFooter dataSource={AppFooterData} isMobile={isMobile} />
        </Router>
    );
}

export default App;
