import React from "react";
import CirculatingSupply from "../components/common/CirculatingSupply";

export const TokenDetailsData = {
    wrapper: {
        className: 'home-page-wrapper feature7-wrapper ktuaz0yrdd-editor_css',
    },
    page: { className: 'home-page feature7' },
    OverPack: { playScale: 0.3 },
    titleWrapper: {
        className: 'feature7-title-wrapper',
        children: [
            {
                name: 'title',
                className: 'feature7-title-h1 ktuaz5rg1wc-editor_css',
                children: (
                    <span>
            <p>Token Details</p>
          </span>
                ),
            },
        ],
    },
    blockWrapper: {
        className: 'feature7-block-wrapper',
        gutter: 24,
        children: [
            {
                md: 6,
                xs: 24,
                name: 'block0',
                className: 'feature7-block',
                children: {
                    className: 'feature7-block-group',
                    children: [
                        {
                            name: 'title',
                            className: 'feature7-block-title ktub05swn1-editor_css',
                            children: (<span><p>Ticker</p></span>),
                        },
                        {
                            name: 'content',
                            className: 'feature7-block-content',
                            children: (<span><h2><b>$RAE</b></h2></span>),
                        },
                    ],
                },
            },
            {
                md: 6,
                xs: 24,
                name: 'block1',
                className: 'feature7-block',
                children: {
                    className: 'feature7-block-group',
                    children: [
                        {
                            name: 'title',
                            className: 'feature7-block-title ktub0h6ga5-editor_css',
                            children: (<span><p>Token Type</p></span>),
                        },
                        {
                            name: 'content',
                            className: 'feature7-block-content',
                            children: (<span><h2><b>ERC-20</b></h2></span>),
                        },
                    ],
                },
            },
            {
                md: 6,
                xs: 24,
                name: 'block2',
                className: 'feature7-block',
                children: {
                    className: 'feature7-block-group',
                    children: [
                        {
                            name: 'title',
                            className: 'feature7-block-title ktub2uqafbi-editor_css',
                            children: (<span><p>Circulating Supply</p></span>),
                        },
                        {
                            name: 'content',
                            className: 'feature7-block-content',
                            children: (<span><h2><CirculatingSupply/></h2></span>),
                        },
                    ],
                },
            },
            {
                md: 6,
                xs: 24,
                name: 'block3',
                className: 'feature7-block',
                children: {
                    className: 'feature7-block-group',
                    children: [
                        {
                            name: 'title',
                            className: 'feature7-block-title ktub4v37swo-editor_css',
                            children: (<span><p>Total Supply</p></span>),
                        },
                        {
                            name: 'content',
                            className: 'feature7-block-content',
                            children: (<span><h2><b>34 Million</b></h2></span>),
                        },
                    ],
                },
            },
        ],
    },
};
