import React from "react";
export const CreatorsDilemmaData = {
    wrapper: { className: 'home-page-wrapper content1-wrapper' },
    OverPack: { className: 'home-page content1', playScale: 0.3 },
    imgWrapper: { className: 'content1-img', md: 12, xs: 24 },
    img: {
        children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
    },
    textWrapper: { className: 'content1-text', md: 12, xs: 24 },
    title: {
        className: 'content1-title ktubee3spsn-editor_css',
        children: (<span><p>Why RAE?</p></span>),
    },
    content: {
        className: 'content1-content',
        children: (
            <span>
                <h2>
                    RAE Solves The Creator’s Dilemma
                </h2>
                <br/>
                <p>
                    As centralized networks mature, platforms can extract more value
                    ("rent") from creator contributions, which diminishes the creators' compensation.
                </p>
                <br/>
                <p>
                    Rent increases because creators have less leverage on the network as it grows.
                    The overall value of networks rapidly multiplies as creators get commoditized.
                    The value of the network surplus is captured by the platform that owns and controls
                    the network making founders and investors rich and leaving content creators
                    earning a salary that is never guaranteed.
                </p>
            </span>
        ),
    },
};
