import React from "react";
export const AboutRaeData = {
    OverPack: {
        className: 'home-page-wrapper content13-wrapper ktu9vf0pfjq-editor_css',
        playScale: 0.3,
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [
            {
                name: 'image',
                children: 'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
                className: 'title-image',
            },
            {
                name: 'title',
                children: (<span><p>About RAE</p></span>),
                className: 'title-h1',
            },
            {
                name: 'content',
                children: (
                    <span>
                        <p>
                            The RAE ecosystem functions as a new, purpose-built layer on top of a public blockchain
                            network. The separation of the digital platform and the digital network, along with the
                            utilization of the RAE Token, allows all of the value provided by content creators and
                            other participants in the ecosystem to be reflected in how those ecosystem participants
                            are rewarded. The RAE Token’s value not only captures the independent use of the ecosystem,
                            but also captures the value generated by digital network effects. That is, as network
                            effects take hold, the increase in the value of work performed on the Ecosystem by network
                            participants is appropriately and automatically reflected in the compensation distributed
                            to network participants in the form of RAE Tokens.
                        </p>
                    </span>
                ),
                className: 'title-content ktu9wjcutb8-editor_css',
            },
        ],
    },
};
