import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from '../../util/Helpers';
import {WHITEPAPER_LINK, RAE_UNISWAP_LINK, BUY_LINK, AUDIT_LINK} from "../../util/Constants";
import {Collapse, Typography} from "antd";

const {Panel} = Collapse;
const {Link} = Typography;

class Content13 extends React.PureComponent {
    render() {
        const { ...props } = this.props;
        const { dataSource } = props;
        delete props.dataSource;
        delete props.isMobile;
        return (
            <div style={{

            }}>
                <OverPack {...props} {...dataSource.OverPack}>
                    <QueueAnim
                        type="bottom"
                        leaveReverse
                        key="page"
                        delay={[0, 100]}
                        {...dataSource.titleWrapper}
                    >
                        {dataSource.titleWrapper.children.map(getChildrenToRender)}
                        <Collapse accordion style={{textAlign: 'left'}}>
                            <Panel
                                key="1"
                                header="What is RAE Token?"
                            >
                                <p>
                                    Every RAE Token burns contribute to fulfilling the requisite RAE Token needed to trigger a new mint. When the mint's designated burn quota is met, RAE Token is minted and queued to be distributed to creators and platforms.
                                </p>
                                <br/>
                                <p>
                                    The next burn quota is calculated using the rolling median of the last 30 mint periods, each normalized to 24 hours. The process ensures that each creator gets the full value they contribute, no matter how busy or slow that day is.
                                </p>
                                <br/>
                                <p>
                                    There will only ever be 34 million RAE Tokens minted. During the first half-life, 10,000 RAE Tokens are minted per period, and that amount is halved every 1,700 minting periods (~4 1/2 years).
                                </p>
                            </Panel>
                            <Panel
                                key="2"
                                header="How are RAE Tokens distributed?"
                            >
                                <p>
                                    Every RAE Token burns contributes to fulfilling the requisite RAE Token needed to trigger a new mint. When the mints designated burn quota is met, RAE Token is minted and queued to be distributed to creators and platforms.
                                </p>
                                <br/>
                                <p>
                                    The next burn quota is calculated based on the rolling median of the last 30 mints each normalized to 24 hours. The process ensures that each creator gets the full value they contribute no matter how busy or slow that day is.
                                </p>
                                <br/>
                                <p>
                                    Initially, each minting period creates and distributes 10,000 RAE Tokens. The number of newly minted RAE Tokens per period is halved every 1,700 periods (~4 1/2 years). For example, around November 2023, each minting period will create and distribute 5,000 RAE Tokens.
                                </p>
                            </Panel>
                            <Panel
                                key="3"
                                header="How do creators earn RAE?"
                            >
                                <p>
                                    Creators on the RAE Network earn RAE tokens based on acquiring and retaining paying subscribers. Each new subscriber is attributed to creator(s) who acquired that subscriber based on Awareness (first-click) and Conversion (last-click). The creator(s) who acquired a subscriber will earn royalties for that subscriber for the lifetime of that subscription.
                                </p>
                                <br/>
                                <p>
                                    As the subscriber renews their subscription, retention royalties are distributed to the creator(s) who helped retain that subscriber. Retention royalties are distributed to creators proportional to the amount of premium content consumed by the subscriber. With time, the percent of retention royalties earned for a specific subscriber increases. For in-depth calculation of the compensation protocol, see Appendix A of the <Link underline href={WHITEPAPER_LINK} rel="noopener noreferrer" target="_blank">whitepaper</Link>.
                                </p>
                            </Panel>
                            <Panel
                                key="4"
                                header="Why not just use USD?"
                            >
                                <p>
                                    Dollars paid to access the RAE Ecosystem's content ignores the value of digital network effects, putting creators in the same spot they are on other platforms, like YouTube.
                                </p>
                                <br/>
                                <p>
                                    In the RAE Ecosystem, digital platforms transmit the value of their revenue to the Ecosystem through the token burning process. For example, Rokfin burns tokens equal to the number of dollars its customers pay to subscribe, which decreases the total available supply of RAE Tokens.
                                </p>
                                <br/>
                                <p>
                                    Because of this need to burn RAE for access, platforms' demand for RAE maps to the subscription revenue they generate.
                                </p>
                                <br/>
                                <p>
                                    More Subscription Revenue = More RAE Demand
                                </p>
                                <br/>
                                <p>
                                    With the decreasing supply of RAE due to burns as one input, and the demand correlated to subscription revenue on the ecosystem as the other, the equilibrium between the two inputs determines the price of each RAE Token at any moment in time.
                                </p>
                            </Panel>
                            <Panel
                                key="5"
                                header="Why don’t creators get paid in cash?"
                            >
                                <p>
                                    If creators got paid in cash, then they would be cashed out of the
                                    future value of the network. Paying in RAE allows them to choose if
                                    they want to cash out immediately or keep their stake in the network
                                    or choose something in between.
                                </p>
                            </Panel>
                            <Panel
                                key="6"
                                header="How do I view RAE Tokens in my wallet?"
                            >
                                <p>
                                    You can view your RAE Token balance in an ERC 20 compatible wallet by watching the RAE Token contract.
                                </p>
                                <br/>
                                <p>
                                    Examples of ERC 20 compatible wallets are Coinbase Wallet, MetaMask, Trust Wallet, and hardware wallets such as Trezor and Ledger (Always buy hardware wallets directly from their website, not Amazon or other third parties).
                                </p>
                                <br/>
                                <p style={{marginLeft: 20}}>
                                    <ul>
                                        <li>
                                            RAE Token Smart Contract Address: 0xE5a3229CCb22b6484594973A03a3851dCd948756
                                        </li>
                                        <li>
                                            Number of Digits: 18
                                        </li>
                                        <li>
                                            Symbol: RAE
                                        </li>
                                    </ul>
                                </p>
                            </Panel>
                            <Panel
                                key="7"
                                header="How do I trade RAE Tokens?"
                            >
                                <p>
                                    You can trade RAE on <Link underline href={RAE_UNISWAP_LINK} rel="noopener noreferrer" target="_blank">Uniswap</Link>.
                                    For detailed instructions, use this <Link underline href={BUY_LINK} rel="noopener noreferrer" target="_blank">quick guide</Link>.
                                </p>
                            </Panel>
                            <Panel
                                key="8"
                                header="Did the RAE contracts undergo a third-party audit?"
                            >
                                <p>
                                    Yes. The RAE smart contracts were audited by Solidified.io, which specializes in smart contract security auditing.
                                </p>
                                <br/>
                                <p>
                                    The full report can be found <Link underline href={AUDIT_LINK} rel="noopener noreferrer" target="_blank">here</Link>.
                                </p>
                            </Panel>
                        </Collapse>
                    </QueueAnim>
                </OverPack>
            </div>
        );
    }
}

export default Content13;
